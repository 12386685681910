import React from 'react';
import { useSelector } from 'react-redux';

function Footer() {
  const { dataM2, lang } = useSelector((state) => state.filters);

  React.useEffect(() => {
    const footer = document.querySelector('footer');
    if (footer) {
      const footerHeight = footer.offsetHeight;
      document.documentElement.style.setProperty('--footer-height', `${footerHeight}px`);
    }
  }, []); // Пустой массив зависимостей, чтобы код выполнился только один раз после монтирования компонента

  const year = new Date().getFullYear();
  return (
    <footer>
      <div className='footer-top'>
        <div className='socnet'>
          <h6>{dataM2.Footer.Social[lang]}</h6>
          <div>
            <a
              href='https://api.whatsapp.com/send/?phone=77473009310&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%21%0A%0A%D0%9F%D0%B8%D1%88%D1%83+%D0%B8%D0%B7+%D1%81%D0%B0%D0%B9%D1%82%D0%B0+Yepo.kz.%0A%0A&type=phone_number&app_absent=0'
              target='blank'>
              <img
                src='socialNetworksIcon/whatsapp.png'
                title='instagram иконки'
                alt='whatsapp'
              />
            </a>
            <a
              href='https://www.instagram.com/yepo.kz?igsh=MWJ3azhyZDJsNzlwcA=='
              target='blank'>
              <img
                src='socialNetworksIcon/free-icon-instagram-2504918.png'
                title='instagram иконки'
                alt='whatsapp'
              />
            </a>
            <a
              href='https://t.me/+77473009310'
              target='blank'>
              <img
                src='socialNetworksIcon/paper-plane.png'
                title='instagram иконки'
                alt='whatsapp'
              />
            </a>
          </div>
        </div>
        {/*  <div>
          <h6>{dataM2.Footer.Numbers[lang]}</h6>
          <a
            href='tel:+77473009310'
            target='blank'
            className='tel'>
            +7 747 300 93 10
          </a>
        </div> */}
        <div className='deep'>
          <h6 className='deeph6'>{dataM2.Footer.Adress[lang]}</h6>
          <div className='deep0'>
            {dataM2.Footer.AdressM[lang].map((obj, i) => {
              return (
                <div
                  className='deep1'
                  key={i}>
                  <div className='deep2'>
                    <a
                      href={obj.link}
                      target='blank'>
                      <p>{obj.city}</p>
                      <p>{obj.mesto}</p>
                      <p>{obj.worktime}</p>
                    </a>
                    {obj.tel && <a href='tel:+7-747-300-93-10'>{obj.tel}</a>}
                  </div>
                </div>
              );
            })}
          </div>

          <a
            target='self'
            href='mailto:yepo.qz@gmail.com'>
            yepo.qz@gmail.com
          </a>
        </div>
      </div>
      <div className='footer-bottom'>
        <div className='developed'>
          Copyright © {year} Yepo.kz. {dataM2.Footer.Rule[lang]}
        </div>
        <div className='developed'>
          <p className='developed-title'>{dataM2.Footer.Developed[lang]}</p>
          <a
            target='blank'
            href='https://www.instagram.com/niyazbektembayev/?igsh=MXI3Yzd1em13eDV4Yw%3D%3D&utm_source=qr'>
            NB DESIGN
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
